import { observer } from "mobx-react";
import { TopImpactsReportingBar } from "./TopImpactsReportingBar_view";
import { Panel } from "../../../../components/ui/Panel";
import { ChangeImpactAssessment } from "./ChangeImpactAssessment/ChangeImpactAssessment_view";
import I18n from "../../../../core/localization/I18n";
import {
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum
} from "../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";
import { ImpactReportingViewModel } from "./ImpactReportingView_model";

interface ImpactReportingViewComponentProps {
  organisationId: number;
  projectId: number;
  model: ImpactReportingViewModel;
}
export const ImpactReportingViewComponent: React.FC<ImpactReportingViewComponentProps> = observer(
  ({ organisationId, projectId, model }) => {
    const noDataLabel = (label: string) => {
      return `No ${label} Data`;
    };

    return (
      <div className="container-fluid">
        <TopImpactsReportingBar
          ragData={model.ragData}
          impactCount={model.impactCount}
          dataConfidence={model.averageDataConfidence}
          organisationId={organisationId}
          projectId={projectId}
        />
        <div className="row">
          <div className="col-md-6">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              <ChangeImpactAssessment
                impacts={model.byTypeChartDataProps.Data}
                dataConfidence={model.byTypeChartDataProps.DataConfidence}
                heading={I18n.t("visualisations.impactsByType")}
                gapAnalysisClick={model.byTypeChartDataProps.gapAnalysisOnClickFunction(
                  ImpactColDefFieldNamesEnum.ImpactTypes
                )}
                drillThroughClick={model.byTypeChartDataProps.drillThroughOnClickFunction(
                  ImpactColDefFieldNamesEnum.ImpactTypes
                )}
                noDataLabel={noDataLabel(I18n.t("visualisations.impactsByType"))}
              />
            </Panel.Panel>
          </div>
          <div className="col-md-6">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byLocationChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byLocationChartDataProps.Data}
                  dataConfidence={model.byLocationChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByLocation")}
                  gapAnalysisClick={model.byLocationChartDataProps.gapAnalysisOnClickFunction(
                    ImpactColDefFieldNamesEnum.Locations
                  )}
                  drillThroughClick={model.byLocationChartDataProps.drillThroughOnClickFunction(
                    ImpactColDefFieldNamesEnum.Locations
                  )}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByLocation"))}
                />
              )}
            </Panel.Panel>
          </div>
        </div>
        <div className="row mb-4">
          <div className={"col-md-6"}>
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byBusinessAreaChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byBusinessAreaChartDataProps.Data}
                  dataConfidence={model.byBusinessAreaChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByBusinessArea")}
                  gapAnalysisClick={model.byBusinessAreaChartDataProps.gapAnalysisOnClickFunction(
                    ImpactColDefFieldNamesEnum.BusinessAreas
                  )}
                  drillThroughClick={model.byBusinessAreaChartDataProps.drillThroughOnClickFunction(
                    ImpactColDefFieldNamesEnum.BusinessAreas
                  )}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByBusinessArea"))}
                />
              )}
            </Panel.Panel>
          </div>
          <div className="col-md-6">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byOwnerAreaChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byOwnerAreaChartDataProps.Data}
                  dataConfidence={model.byOwnerAreaChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByOwner")}
                  gapAnalysisClick={model.byOwnerAreaChartDataProps.gapAnalysisOnClickFunction(
                    CommonColDefFieldNamesEnum.Owner
                  )}
                  drillThroughClick={model.byOwnerAreaChartDataProps.drillThroughOnClickFunction(
                    CommonColDefFieldNamesEnum.Owner
                  )}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByOwner"))}
                />
              )}
            </Panel.Panel>
          </div>
        </div>
      </div>
    );
  }
);
