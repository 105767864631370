import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import I18n from "../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useEffect, useState } from "react";
import { ProcessReportingViewModel } from "./ProcessReportingView_model";
import { observer } from "mobx-react-lite";
import ProjectProcessesApi from "../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { ImpactProgressStatusChart } from "./charts/ImpactProgressStatusChart/ImpactProgressStatusChart_view";
import Pages from "../../../../../routes/InsightRoutes";
import { CommonColDefFieldNamesEnum } from "../../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";
import { MapProgressStatusesFromActionStackBarChart } from "../../Actions/ActionSummary/ActionSummary_mappers";
import { UrlOperators } from "../../../../../core/grids/builder/directorBuilder/grids/base/addons/FilterStoreAddon";
import { ActionChartsWrapper } from "./charts/ActionChartsWrapper/ActionChartsWrapper_view";
import { GridTypes } from "../../../../../enums";

export const ProcessReportingView: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [model] = useState(
    () => new ProcessReportingViewModel(organisationId, +projectId, GridTypes.PROCESS_REPORTS_LINK_MODAL_GRID)
  );
  const mappedProgressStatuses = MapProgressStatusesFromActionStackBarChart();

  useEffect(() => {
    model.onMount();

    return () => {
      model.onUnmount();
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col px-0 text-right">
            <Button className="px-2" type={ButtonTypes.LINK} onClick={model.handleBasicFilterClick}>
              <Icon symbol={IconSymbols.Filter} className="mr-2" />
              {I18n.t("phrases.filter")}
            </Button>
            <Button className="px-2" type={ButtonTypes.LINK} onClick={model.handleAdvancedFilterClick}>
              <Icon symbol={IconSymbols.Sliders} className="mr-2" />
              {I18n.t("phrases.advancedFilter")}
            </Button>
            <Button
              className="px-2"
              type={ButtonTypes.LINK}
              onClick={model.clearFilters}
              isDisabled={!model.filterSelectedItemIds}
            >
              <Icon symbol={IconSymbols.ClearFilter} className="mr-2" />
              {I18n.t("phrases.clearFilters")}
            </Button>
          </div>
        </div>
      </div>
      <hr className="m-0" />
      <div className="chart-container container-fluid">
        <div className="row my-5">
          <div className="col">
            <ImpactProgressStatusChart
              title={I18n.t("phrases.processImpacts")}
              loadDataFn={ProjectProcessesApi.getProjectProcessImpactReport}
              selectedItemIds={model.filterSelectedItemIds}
              transformer={data => ({
                data,
                tooltipLabelFn: item => {
                  return `${I18n.t(`phrases.${item.id}`)} - ${item.indexValue}`;
                },
                onClick: item => {
                  const url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}?${
                    CommonColDefFieldNamesEnum.ProgressStatus
                  }=${mappedProgressStatuses.find(e => e.id === item.id).label}&${
                    CommonColDefFieldNamesEnum.Processes
                  }=${UrlOperators.contains.symbol}${item.data.name}`;
                  navigate(url);
                },
                indexBy: "name",
                keys: ["completed", "inProgress", "notStarted"],
                bottomAxisLabel: I18n.t("phrases.processes"),
                colors: ["#58C8D1", "#FFC036", "#E5E5E5"],
                legendLabel: e => {
                  return I18n.t(`phrases.${e.id}`);
                },
                leftAxisLabel: I18n.t("phrases.noOfImpacts")
              })}
            />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <ActionChartsWrapper
              organisationId={organisationId}
              projectId={+projectId}
              filterSelectedItemIds={model.filterSelectedItemIds}
            ></ActionChartsWrapper>
          </div>
        </div>
      </div>
    </>
  );
});
