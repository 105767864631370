import { action, makeObservable, observable } from "mobx";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../services/api/v2/impacts/Impacts.api";

export class ImpactsGridModalModel {
  organisationId: number;
  projectId: number;
  impactsProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  @observable impacts: FP.Entities.IImpactSummary[];
  @observable columnDefs: FP.Entities.IColumnDef[];

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
  }

  onMount = async () => {
    await this.loadImpacts();
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  loadImpacts = async () => {
    const res = await this.impactsProvider.getGridData(this.organisationId, this.projectId);

    if (!res.payload || res.isError) return;
    this.setImpacts(res.payload.data);
    this.setColumnDefs(res.payload.columnDefs);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
  @action
  setImpacts = (impacts: FP.Entities.IImpactSummary[]) => {
    this.impacts = impacts;
  };
  @action
  setColumnDefs = (columnDefs: FP.Entities.IColumnDef[]) => {
    this.columnDefs = columnDefs;
  };
}
