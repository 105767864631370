import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import { BenefitField } from "../../../../../../../../enums";
import { SingleFormModel } from "../../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getBenefitDescriptionFormFields } from "./BenefitDescription_fields";
import BenefitApi, { BenefitApi as IBenefitApi } from "../../../../../../../../services/api/v2/benefits/Benefits.api";

export class BenefitDescriptionModel {
  benefitsProvider: IBenefitApi;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable.ref benefit: FP.Entities.IBenefit;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  benefitId: number;
  formModel: SingleFormModel;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, benefitId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.benefitsProvider = BenefitApi;
    this.benefitId = benefitId;
    this.formModel = new SingleFormModel();
    this.closeModalFn = closeModalFn;
  }

  onMount = async () => {
    await this.loadBenefit(this.benefitId);
    this.formModel.formFields = getBenefitDescriptionFormFields(this.benefit);
    this.formModel.actions = this.getFormActions();
  };

  getFormActions = () => [
    {
      id: "cancel",
      label: I18n.t("phrases.cancel"),
      onAction: ev => {
        this.closeModalFn();
      },
      rendersIn: UiActionRenderers.BUTTON,
      componentProps: {
        type: ButtonTypes.OUTLINE_PRIMARY,
        className: ""
      }
    },
    {
      id: "save",
      label: I18n.t("phrases.save"),
      onAction: async () => {
        let descriptionValue = this.formModel.getFormKeyExtractedValue()["description"];
        let res = await this.benefitsProvider.updateField(
          this.organisationId,
          this.projectId,
          this.benefitId,
          descriptionValue,
          0,
          null,
          0,
          BenefitField.description
        );
        if (!res || res.isError) return;
        this.closeModalFn();
      },
      rendersIn: UiActionRenderers.BUTTON,
      componentProps: {
        type: ButtonTypes.PRIMARY,
        className: "ml-2"
      }
    }
  ];

  @action
  setBenefit = (benefit: FP.Entities.IBenefit) => {
    this.benefit = benefit;
    this.isLoading = false;
  };

  @action
  loadBenefit = async (benefitId: number) => {
    let res = await this.benefitsProvider.getById(this.organisationId, this.projectId, benefitId);
    if (!res || res.isError) return;

    this.setBenefit(res.payload);
  };
}
