import { ImpactsGridModalView } from "./modals/ImpactsGridModal/ImpactsGridModal_view";

export const SHOW_IMPACT_ADVANCED_FILTER_MODAL = (
  onAssignSuccess: (itemIds: number[]) => void,
  selectedItemIds: number[]
) => {
  return {
    showClose: false,
    title: null,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    content: (
      <ImpactsGridModalView
        gridToolbarType="link-modal"
        onAssignSuccess={onAssignSuccess}
        selectedItemIds={selectedItemIds}
      />
    )
  };
};
