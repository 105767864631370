export enum CommonColDefFieldNamesEnum {
  Selected = "selected",
  RefNumber = "refNumber",
  Name = "name",
  Owner = "owner",
  Tags = "tagNames",
  StartDate = "startDate",
  EndDate = "endDate",
  ProgressStatus = "progressStatus",
  CreatedBy = "createdByName",
  CreatedAt = "createdAt",
  ModifiedBy = "modifiedByName",
  UpdatedAt = "updatedAt",
  ReviewedBy = "reviewedByName",
  LastReviewedOn = "lastReviewedOn",
  NextReviewDue = "nextReviewDue",
  NoteCount = "noteCount",
  Description = "description",
  Impacts = "impacts",
  ProjectName = "projectName",
  ProgrammeName = "programmeName",
  Deadline = "deadline",
  Processes = "processes"
}

export enum ProcessColDefFieldNamesEnum {
  UniqueIdentifier = "uniqueIdentifier",
  Level1 = "level1",
  Level2 = "level2",
  Level3 = "level3",
  Level4 = "level4",
  Level5 = "level5",
  ScopeItems = "scopeItems",
  IsInScope = "isInScope"
}

export enum ProjectProcessColDefFieldNamesEnum {
  ProcessApps = "processApps",
  ProcessRoles = "processRoles",
  ImpactGroups = "impactGroups",
  Impacts = "impacts",
  Actions = "actions",
  ProcessJobRoles = "processJobRoles",
  StakeholderOwners = "stakeholderOwners",
  Tags = "tags"
}

export enum ImpactColDefFieldNamesEnum {
  ImpactTypes = "impactTypes",
  TotalPeopleImpacted = "totalPeopleImpacted",
  ImpactLevel = "impactLevel",
  BusinessAreas = "businessAreas",
  Locations = "locations",
  ImpactGroups = "impactGroups",
  Actions = "actions",
  ProjectStakeholders = "projectStakeholderNames",
  Audiences = "audiences",
  Milestones = "milestones"
}

export enum ActionColDefFieldNamesEnum {
  RagStatus = "ragStatus",
  AssignedTo = "assignedToName",
  ActionStakeholders = "actionStakeholders",
  ActionAudiences = "actionAudiences",
  ProjectStakeholderOwners = "projectStakeholderOwners",
  ActionTypeName = "actionTypeName"
}

export enum StakeholderColDefFieldNamesEnum {
  Email = "email",
  Role = "role",
  BusinessArea = "businessArea",
  Influence = "influence",
  OwnerNames = "owners",
  IsKeyStakeholder = "isKeyStakeholder",
  Sentiment = "sentiment",
  Commitment = "commitment",
  Receptiveness = "receptiveness",
  OwnedActions = "ownedActions",
  Impact = "impact",
  Tags = "tags",
  CreatedBy = "createdBy",
  CreatedAt = "createdOn",
  ModifiedBy = "modifiedBy",
  UpdatedAt = "updatedOn",
  ReviewedBy = "reviewedBy",
  LastReviewedOn = "reviewedOn",
  NextReviewDue = "nextReviewDue",
  NoteCount = "notes",
  StakeholderId = "stakeholderId"
}

export enum AudienceColDefFieldNamesEnum {
  Impact = "impact",
  IsStakeholder = "isStakeholder",
  Size = "size",
  Influence = "influence",
  Count = "count",
  AudienceCount = "audienceCount",
  Awareness = "awareness",
  Understanding = "understanding",
  Commitment = "commitment",
  Capability = "capability",
  Adoption = "adoption",
  UiActionCol = "uiActionCol"
}

export enum MilestoneColDefFieldNamesEnum {
  Deadline = "deadline",
  MilestoneTypes = "milestoneTypes"
}
