import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactsGridModalModel } from "./ImpactsGridModal_model";
import { useEffect, useState } from "react";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { ImpactsGrid } from "../../../../../../core/grids/builder/directorBuilder/grids/ImpactsGrid/ImpactsGridView_view";
import { GridTypes } from "../../../../../../enums";

const ImpactsGridModalView: React.FunctionComponent<any> = observer(({ onAssignSuccess, selectedItemIds }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ImpactsGridModalModel(+organisationId, +projectId));

  useEffect(() => {
    model.onMount();
    return () => model.onUnmount();
    //eslint-disable-next-line
  }, []);

  if (model.isLoading) return <PositionedSpinner />;
  return (
    <div className="impacts-modal-view insight-tab-content__space-filler" style={{ height: "100%" }}>
      <ImpactsGrid
        isLoading={model.isLoading}
        data={model.impacts}
        gridToolbarType="link-modal"
        onAssignSuccess={onAssignSuccess}
        backendColumnDefs={model.columnDefs}
        preSelectedItemIds={selectedItemIds}
        gridType={GridTypes.IMPACTS_REPORTS_LINK_MODAL_GRID}
      />
    </div>
  );
});

export { ImpactsGridModalView };
