import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useFlightPathUser } from "../../../../core/auth_insight";
import I18n from "../../../../core/localization/I18n";
import { GridTypes } from "../../../../enums";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactReportingViewComponent } from "./ImpactReportingView_component";
import { ImpactReportingViewModel } from "./ImpactReportingView_model";

export interface ImpactReportingViewProps {}

export const ImpactReportingView: React.FC<ImpactReportingViewProps> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string; page: string }>();
  const authUser = useFlightPathUser();
  const [model] = useState(
    () =>
      new ImpactReportingViewModel(
        organisationId,
        +projectId,
        authUser,
        navigate,
        GridTypes.IMPACTS_REPORTS_LINK_MODAL_GRID
      )
  );

  useEffect(() => {
    model.onMount();
    return () => model.onUnmount();
    // eslint-disable-next-line
  }, [model]);

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col px-0 text-right">
            {/* <Button className="px-2" type={ButtonTypes.LINK} onClick={model.handleBasicFilterClick}>
              <Icon symbol={IconSymbols.Filter} className="mr-2" />
              {I18n.t("phrases.filter")}
            </Button> */}
            <Button className="px-2" type={ButtonTypes.LINK} onClick={model.handleAdvancedFilterClick}>
              <Icon symbol={IconSymbols.Sliders} className="mr-2" />
              {I18n.t("phrases.advancedFilter")}
            </Button>
            <Button
              className="px-2"
              type={ButtonTypes.LINK}
              onClick={model.clearFilters}
              isDisabled={!model.filterSelectedItemIds}
            >
              <Icon symbol={IconSymbols.ClearFilter} className="mr-2" />
              {I18n.t("phrases.clearFilters")}
            </Button>
          </div>
        </div>
      </div>
      <hr className="m-0" />
      {model.isLoading ? (
        <PositionedSpinner></PositionedSpinner>
      ) : (
        <ImpactReportingViewComponent organisationId={organisationId} projectId={+projectId} model={model} />
      )}
    </>
  );
});
