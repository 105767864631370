import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { ProcessesGridColumnBuilder } from "./ProcessesGridView_columns";
import { CommonColDefFieldNamesEnum, ProcessColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { AppGridState } from "../base/AppGrid_view";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { GetProcessesGridActions } from "./ProcessesGridView_actions";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import ProcessesApi, { ProcessesApi as IProcessesApi } from "../../../../../../services/api/v2/processes/Processes.api";
import { SHOW_PROCESS_DELETE_CONFIRM_MODAL, SHOW_PROCESS_UPLOADER_MODAL } from "./ProcessesGridView_modals";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterStoreAddon";
import { EntityTypes } from "../../../../../../enums";
import { ColDef } from "ag-grid-community";

export class ProcessGridModel {
  organisationId: number;
  projectId: number;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  userCanViewImpacts: boolean;
  processesGridColumnBuilder: ProcessesGridColumnBuilder;
  @observable selectedItems: number[] = [];
  @observable.ref gridActions: IGridUiAction[] = [];
  httpProgress: IProgressIndicatorModel;
  processProvider: IProcessesApi;
  refreshDataHandler: any;
  @observable filterStoreAddon: FilterStoreAddon;
  @observable columnStateAddon: ColumnStateAddon;
  @observable textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  @observable gridColumns: ColDef<any, any>[];
  onFieldUpdate: () => void;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    refreshDataHandler: any,
    onFieldUpdate: () => void
  ) {
    makeObservable(this);
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.httpProgress = ProgressIndicatorModel;
    this.processProvider = ProcessesApi;
    this.refreshDataHandler = refreshDataHandler;
    this.onFieldUpdate = onFieldUpdate;
  }

  onMount = async () => {
    this.generateAddons();
    this.generateGridConfig();
    this.generateActions();
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  @action
  generateGridConfig = () => {
    this.processesGridColumnBuilder = new ProcessesGridColumnBuilder({
      canEdit: true,
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewImpacts: this.userCanViewImpacts,
      onFieldUpdate: this.onFieldUpdate,
      columns: [
        CommonColDefFieldNamesEnum.Selected,
        ProcessColDefFieldNamesEnum.UniqueIdentifier,
        ProcessColDefFieldNamesEnum.Level1,
        ProcessColDefFieldNamesEnum.Level2,
        ProcessColDefFieldNamesEnum.Level3,
        ProcessColDefFieldNamesEnum.Level4,
        ProcessColDefFieldNamesEnum.Level5,
        ProcessColDefFieldNamesEnum.ScopeItems
      ]
    });
    this.gridColumns = this.processesGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetProcessesGridActions(this));
  };

  @action
  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, EntityTypes.PROCESSES);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: EntityTypes.PROCESSES,
      filterHasChangedFn: this.setIsFilterChanged
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: EntityTypes.PROCESSES,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  assignProcesses = async (selectedItems: any) => {
    this.httpProgress.showOverlay();
    const res = await this.processProvider
      .createOrganisationProcess(this.organisationId, selectedItems)
      .then(e => this.refreshDataHandler());
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    return res.payload;
  };

  showProcessConfirmDeleteModal = () => {
    return SHOW_PROCESS_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  showProcessUploaderModal = () => {
    return SHOW_PROCESS_UPLOADER_MODAL(this.onFieldUpdate);
  };
  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.processProvider
      .deleteRange(this.organisationId, this.projectId, itemIds)
      .then(e => this.refreshDataHandler());
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };
}
